import '../../styles/pages/spring-2020/boundless-opportunity.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import { Parallax, Background } from 'react-parallax';
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-2020-stories/more-stories"
import StoryNav from "../../components/spring-2020-stories/story-nav"
import Related from "../../components/spring-2020-stories/related-stories"

import Heiner from '../../images/spring-2020/boundless-opportunity/heiner.jpg';
import Torres from '../../images/spring-2020/boundless-opportunity/sergio-torres.jpg';
import TorresSchedule from '../../images/spring-2020/boundless-opportunity/torres-schedule.jpg';
import Cavitt from '../../images/spring-2020/boundless-opportunity/cavitt.jpg'
import CavittTorres from '../../images/spring-2020/boundless-opportunity/cavitt-torres.jpg'
import House from '../../images/spring-2020/boundless-opportunity/john-irwin-memorial-house.jpg'

import socialBanner from "../../images/spring-2020/social-banners/boundless-opportunity.jpg";



export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Boundless Opportunity';
    var pageDesc = 'During his 22 years of incarceration, James “JC” Cavitt dreamed about everything he would do when he got out: He would finish school, get a good job, raise a family and give back to the community.';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-2020">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="boundless-opportunity" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <section className="feature-hero animated fadeIn slow">
          <div className="hero-text">
            <h1><span className="first-word animated fadeInUp delay-1s">Boundless</span> <span className="second-word animated fadeInDown delay-1s">Opportunity</span></h1>
            <p className="sub-heading animated fadeInUp delay-2s">Project Rebound Opens Doors for the Formerly Incarcerated</p>
            <p className="author animated fadeInUp delay-2s">Story by Lynn Juliano, Photos by Matt Gush</p>
          </div>
        </section>
        <article className="wrap small">
          
          <ScrollAnimation animateIn="fadeIn">
          <p><span className="intro-text">During his 22 years of incarceration</span>, James “JC” Cavitt dreamed about everything he would do when he got out: He would finish school, get a good job, raise a family and give back to the community.</p>
          
        <Related slug="boundless-opportunity" />

          
          <p>The night before Cavitt was due to be paroled from San Quentin State Prison, he heard about Cal State Fullerton’s Project Rebound — a program that supports the higher education and successful reintegration of formerly incarcerated individuals.</p>
          <p>Cavitt paroled on a Friday and arrived on campus the following Tuesday.</p>
          
          <h2>A Program of ‘Second Chances’</h2>
          <p>When Cavitt first visited CSUF, he remembers being overwhelmed by the size of the campus and wondering if the 3.42 GPA he maintained while earning his associate’s degree through the Prison University Project measured up in a collegiate environment.</p>
          <p>As part of Project Rebound — which offers individualized support with admissions, academic advising, tutoring, mentoring, counseling, legal and career services — Cavitt was empowered to complete his bachelor’s degree in two years.</p>
          <p>“A lot of days I have to pinch myself,” he admits. “I’m so blessed that I now have the opportunity to live out my dreams.”</p>
          </ScrollAnimation>
        </article>
        <ScrollAnimation animateIn="fadeIn">
        <Parallax
        bgImage={Cavitt}
        bgImageAlt="JC Cavitt smiling"
        strength={200}
        className="full-width">
          <div className="caption">
          JC Cavitt
          </div>
          <div className="height" />
        </Parallax>
        </ScrollAnimation>
        <article className="wrap small">
          {/* <figure className="align-wide">
            <img src={Cavitt} alt="James “JC” Cavitt" />
            <figcaption>James “JC” Cavitt</figcaption>
          </figure> */}
          <ScrollAnimation animateIn="fadeIn">
          <p>Cavitt ’19 (B.A. sociology) is now continuing his academic journey as a master of social work student at Cal State Long Beach. The husband and father of one stays connected to his alma mater, serving as the housing and outreach coordinator for CSUF’s Project Rebound.</p>
          <p>“Project Rebound is designed to help students like me get in, when everything else is designed to keep us out,” reflects Cavitt.</p>
          <p>While CSUF’s Project Rebound is only four years old — the program originated at sister campus San Francisco State University in 1967 — it has quickly become a national model.</p>
          <p>In February 2020, the California State University system established the Project Rebound Consortium as a state-funded, multi-campus collaboration to oversee the nine campuses that currently operate Project Rebound programs as well as expansions of the program to other CSU campuses. Brady Heiner, founder and executive director of CSUF’s Project Rebound, was elected founding chair of the consortium and President Fram Virjee serves as lead president.</p>

          <figure className="align-right large">
            <img src={Heiner} alt="Brady Heiner smiling" />
            <figcaption>Brady Heiner</figcaption>
          </figure>

          <p>“The consortium is a momentous stride in Project Rebound’s pursuit of equitable access, opportunity and social justice for people impacted by the criminal legal system,” says Heiner.</p>

          <p>To date, 13 students have graduated with assistance from CSUF’s Project Rebound. Seventy students are currently enrolled and 14 are slated to graduate this spring.</p>

          <p>“Students come to us with a great deal of grit and persistence. We help to cultivate that into positive outcomes for them and the community,” explains Heiner, also an associate professor of philosophy. “It’s a program of second chances.”</p>

          <p>While California’s recidivism rate is 50%, CSUF’s Project Rebound boasts a recidivism rate of 0%. In addition, 100% of the program’s graduates have secured jobs or enrolled in post-graduate programs and the average GPA for CSUF’s Project Rebound students is 3.33.</p>

          <p>“The model is working,” says Heiner. “When our students gain access to opportunities, they are doing great things with them.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <h2>Home Away From Home</h2>

          <p>In 2018, CSUF became the first university in the nation to offer housing for a select group of Project Rebound students. The five-bedroom John Irwin Memorial House is named for the late SFSU Project Rebound founder, who spent five years in state prison before pursuing a doctorate in sociology and becoming a university professor and internationally renowned expert on prisons.</p>

          <figure className="align-left large">
            <img src={House} alt="View of John Irwin Memorial House" />
            <figcaption>John Irwin Memorial House</figcaption>
          </figure>
          
          <p>The housing initiative, along with student scholarships, are supported by such partners as the Bickerstaff Family Foundation, DreamMaker Foundation, McMillen Family Foundation, North Orange County Public Safety Task Force, Orange County Chapter of Sigma Pi Phi, Yerba Buena Fund and dozens of individual donors.</p>
          <p>Six students reside in the house, which Heiner describes as a place where they can receive academic, personal and social support living in a transformative environment. The house serves as a community space for all Project Rebound students, alumni and community partners.</p>
          <p>The house is a key part of the program, says Cavitt, because of the sense of safety it provides.</p>
          <p>“Many of our students come from environments with trauma, violence, sirens and gang activity,” explains Cavitt. “There’s a lot of ‘looking over your shoulder.’”</p>
          <p>When students feel physically safe, says Cavitt, they are able to let their guard down. “They can be vulnerable and talk about some of the things they normally wouldn’t talk about in the environments they come from.”</p>
          <p>Sergio Torres, a liberal studies major, is one of six students currently living in the house. A typical week for him is filled with classes, work, club meetings and community service. Sundays are reserved for studying.</p>
          </ScrollAnimation>
        </article>
        <ScrollAnimation animateIn="fadeIn">
        <Parallax
        bgImage={Torres}
        bgImageAlt="Sergio Torres with open book in hands"
        strength={400}
        className="full-width">
          <div className="caption">
          Sergio Torres
          </div>
          <div className="height" />
        </Parallax>
        </ScrollAnimation>
        <article className="wrap small">
          {/* <figure className="align-wide">
            <img src={Torres} alt="Sergio Torres" />
          </figure> */}

          <ScrollAnimation animateIn="fadeIn">
          <p>He points to the dining room, with a large window overlooking the quiet neighborhood and a skylight on which a steady patter of rain provides a calming presence in the winter. “It’s my favorite space in the house,” he shares. “It’s where everyone gathers with the same goal — to do well this semester.”</p>
          <p>While incarcerated, Torres decided he had enough of being in jail and wanted to pursue higher education. “I’m thankful to say that going to school saved me.”</p>
          <p>Cavitt remembers Torres, a transfer student from Santa Ana Community College, springing onto campus with “so much energy” and “a big, bright smile.”</p>
          <p>Learning that Torres was in need of housing, Cavitt met with him to see if he was a good match. He immediately developed a trust in Torres, calling him the “eyes and ears” of the house. “Sergio has been excelling,” says Cavitt. “He’s come up against some challenges, but he keeps at it.”</p>
          <p>Living in the house has given Torres the opportunity to step out of his past environment, to “be independent” and to “grow as a man.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <h2>Leaders in the Making</h2>
          <figure className="align-wide">
            <img src={CavittTorres} alt="Sergio Torres and JC Cavitt pose for photo" />
            <figcaption>Sergio Torres and JC Cavitt</figcaption>
          </figure>
          <p>There’s a dissonance for Torres, who was raised in Santa Ana: While certain areas of the city face challenges with crime, it’s also where his mom created a loving home for their family.</p>
          <p>“Sometimes it’s a very bad place,” he says, “but it’s also a beautiful place. I call it my home, but there needs to be a lot of change.”</p>
          <p>The city inspires Torres’ passion for Chicano culture, which prompted him to add a minor in Chicano studies to his coursework and launch the Alianza Chicanx club on campus.</p>
          <p>Some of the club’s efforts include creating pathways for Chicano community college students to successfully transition to CSUF, educating students about legislation that could directly impact them, and serving the community through activities such as distributing 600 burritos to day laborers at a local Home Depot.</p>
          <p>“I think many Project Rebound students, especially Sergio, see injustices in the world and don’t want to sit back,” explains Cavitt. “He is strategizing and moving beyond conversations to make changes in his community.”</p>
          <p>“Just because we have a certain past doesn’t mean it defines us,” says Torres, who credits the program with helping him gain the confidence to become a leader.</p>
          <p>“Without Project Rebound, I would have been just one out of 40,000 students on campus,” he says. “Now I have a close network of people who are rooting for me.”</p>
          <p>Torres plans to graduate in spring 2021 and wants to continue his education, with the goal of becoming an academic adviser at a community college.</p>
          <p>Last November, Torres spoke about his experience with Project Rebound at the National Conference on Higher Education in Prison.</p>
          <p>“Everyone’s looking to Project Rebound for advice on dismantling systems of oppression,” says Torres. “It’s very empowering to know that people are talking about this program around the nation and that our university is leading the way.” <span className="end-mark" /></p>

          <p className="learn-more">To learn more about Project Rebound, visit <a href="https://www.fullerton.edu/rebound/" target="_blank">fullerton.edu/rebound</a>.</p>
          </ScrollAnimation>

        </article>
        <MoreStories slug="boundless-opportunity" />
        <StoryNav slug="boundless-opportunity" />
      </Layout>
    );
  }
} 